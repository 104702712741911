<template>
   <div class="basedInspection">
     <div class="tops">
       <div class="tops-left">
         <div class="tops-left-a">
           <p style="opacity: 0;">o</p>
           <p>OD</p>
           <p>OS</p>
         </div>
         <div>
           <p class="yw">
             <PopularScience :text="'眼位'" :gjz="'yanwei'"></PopularScience>
           </p>
           <el-select
               class="srr"
               v-model="ywValue"
               @change="ywChange"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in yw"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </div>
         <div>
           <p style="opacity: 0;">眼位</p>
           <el-input class="srr" v-model="postData.S_OP_EC_PositionOD_extra" placeholder="请输入内容"></el-input>
         </div>
       </div>
       <div class="tops-right">
         <div class="yqyds">
           <p><PopularScience :text="'眼球运动'" :gjz="'yanqiuyundong'"></PopularScience></p>
           <el-select
               class="srr"
               v-model="yqydValue"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               @change="ydChange"
               placeholder="请选择或输入">
             <el-option
                 v-for="item in yqyd"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
           <el-select
               class="srr"
               v-model="yqydValuez"
               filterable
               allow-create
               multiple
               collapse-tags
               default-first-option
               @change="ydzChange"
               placeholder="请选择文章标签">
             <el-option
                 v-for="item in yqyd"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </div>
         <div class="tops-right-last">
           <p style="opacity: 0;">飒飒</p>
           <el-input class="srr" v-model="postData.S_OP_EC_CampaignOD_extra" placeholder="请输入内容"></el-input>
           <el-input class="srr" v-model="postData.S_OP_EC_CampaignOS_extra" placeholder="请输入内容"></el-input>
         </div>
<!--         <div class="tops-right-last">-->
<!--           <p>+</p>-->
<!--           <p>+</p>-->
<!--         </div>-->
       </div>
     </div>
   </div>
</template>

<script>
export default {
  name: "basedInspection",
  data() {
    return {
      ids: 3,
      off: false,
      bz: '',
      yw: [
        {
          value: '内斜',
          label: '内斜'
        },
        {
          value: '内隐斜',
          label: '内隐斜'
        },
        {
          value: '外斜',
          label: '外斜'
        },
        {
          value: '外隐斜',
          label: '外隐斜'
        },
        {
          value: '正位视',
          label: '正位视'
        },
      ],
      yqyd: [
        {
          value: '无明显受限',
          label: '无明显受限'
        },
        {
          value: '眼球震颤',
          label: '眼球震颤'
        },
        {
          value: '外展障碍',
          label: '外展障碍'
        },
        {
          value: '内收障碍',
          label: '内收障碍'
        },
        {
          value: '上转障碍',
          label: '上转障碍'
        },
        {
          value: '下转障碍',
          label: '下转障碍'
        },
      ],
      ywValue: [],
      yqydValue: [],
      yqydValuez: [],
      postData: {},
      chooseid: ''
    }
  },
  props: {
    idx: {
      default: -1
    }
  },
  watch: {
    idx: function (n, o) {
      if (n == this.ids && this.chooseid != this.$store.state.physicianVisits.xzConsulting) {
        if (this.$store.state.physicianVisits.xzConsulting) {
          this._api.eyeTest.getBaseTest()
              .then(res => {
                if (res.GetListResult) {
                  this.$store.commit('eyeTest/upBaseTestData', this._clJson(res.GetListResult[0].S_OP_Json))
                  this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
                  this.ywValue = this.postData.S_OP_EC_PositionOD.split(',')
                  this.yqydValue = this.postData.S_OP_EC_CampaignOD.split(',')
                  this.yqydValuez = this.postData.S_OP_EC_CampaignOS.split(',')
                }
                this.chooseid = this.$store.state.physicianVisits.xzConsulting
                this.$store.commit('eyeTest/upBaseTestDataCid', this.chooseid)
              })
        }
      }
    },
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upBaseTestData', n)
      },
      deep: true
    }
  },
  created() {
    this.postData = this.$store.state.eyeTest.datas
    if (this.postData.S_OP_EC_PositionOD) {
      this.ywValue = this.postData.S_OP_EC_PositionOD.split(',')
    }
    if (this.postData.S_OP_EC_CampaignOD) {
      this.yqydValue = this.postData.S_OP_EC_CampaignOD.split(',')
    }
    if (this.postData.S_OP_EC_CampaignOS) {
      this.yqydValuez = this.postData.S_OP_EC_CampaignOS.split(',')
    }
  },
  methods: {
    dkselect() {
      this.off = true
    },
    bh() {
      // console.log(this.yqyd)
    },
    ywChange(e) {
      if (e.length > 0) {
        let it = this.yw.some((item) => item.value == e[e.length-1])
        if (!it) {
          let item = e[e.length-1]
          this.ywValue = []
          this.ywValue.push(item)
        }else {
          if (e.length > 1) {
            let its = this.yw.some((item) => item.value == e[0])
            if (!its) {
              this.ywValue.shift()
            }
          }
        }
        this.postData.S_OP_EC_PositionOD = this.ywValue.join(',')
      }else {
        this.postData.S_OP_EC_PositionOD = ''
      }
    },
    ydChange(e) {
      this.yqydbh(e, 0)
    },
    ydzChange(e) {
      this.yqydbh(e, 1)
    },
    yqydbh(e, type=0) {
      if (e.length > 0) {
        let it = this.yqyd.some((item) => item.value == e[e.length-1])
        if (!it) {
          let item = e[e.length-1]
          if (type == 0) {
            this.yqydValue = []
            this.yqydValue.push(item)
          }else {
            this.yqydValuez = []
            this.yqydValuez.push(item)
          }
        }else {
          if (e.length > 1) {
            if (e[0] == '无明显受限') {
              this.yqydValue.shift()
            }
            let its = this.yqyd.some((item) => item.value == e[0])
            if (!its) {
              if (type == 0) {
                this.yqydValue.shift()
              }else {
                this.yqydValuez.shift()
              }
            }
          }
        }
        if (type == 0) {
          if (e[e.length-1] == '无明显受限') {
            this.yqydValue = ['无明显受限']
          }
          this.postData.S_OP_EC_CampaignOD = this.yqydValue.join(',')
        }else {
          if (e[e.length-1] == '无明显受限') {
            this.yqydValuez = ['无明显受限']
          }
          this.postData.S_OP_EC_CampaignOS = this.yqydValuez.join(',')
        }
      }else {
        if (type == 0) {
          this.postData.S_OP_EC_CampaignOD = ''
        }else {
          this.postData.S_OP_EC_CampaignOS = ''
        }
      }
    }
  },
  components: {
    Liexian: () => import('@/components/specialSurveys/eyePart/liexian')
  }
}
</script>

<style src="../../../assets/css/vue-multiselect.min.css"></style>
<style scoped lang="scss">
  .basedInspection{
    width: 100%;
  }
  .margin-30 {margin-bottom: 20px;}
  .tops {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: center;
    input {
      display: block;
      outline: none;
      border-radius: 5px;
      border: 1px solid #000000;
      height: 30px;
      text-align: center;
      width: 8vw;
    }
    .tops-left {
      display: flex;
      justify-content: center;
      align-items: center;
      .tops-left-a {
        p:nth-child(2){
          padding-bottom: 30px;
        }
      }
      .tops-left-b {
        margin:0 20px;
      }
      .yw {
        position: relative;
        bottom: 30px;
      }
    }
    .tops-right {
      display: flex;
      align-items: flex-end;
      align-items: center;
      padding-left: 0px;
      .tops-right-a{
        margin-bottom: 20px;
        margin-right: 30px;
      }
      p {padding-bottom: 5px}
      .yqyds {
        overflow: hidden;
        //width: 2.3rem;
        position: relative;
      }
    }
  }
  .xlxz {
    display: block;
    width: 10vw;
  }
  .medd {
    width: 90%;
    margin: 0 auto;
    padding-top: 0.3rem;
  }
  ::v-deep .medd .el-collapse-item__header {
    font-size: 18px;
  }
  ::v-deep .medd .el-collapse-item__wrap {overflow: visible}
  .srr {width: 2.5rem; margin:0.1rem 0.3rem;display: block;}
</style>
